.site-modal {
  display: none;
  &__content {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99999;
    display: flex;
    flex-direction: column;
    min-width: 100%;
    margin: 0 auto;
    pointer-events: none;

    justify-content: center;
  }
  &__body {
    padding: 5px;
    margin: 40px;
  }
  &__video-container {
    // position: relative;
  }
  &__embed {
    position: relative;
    display: flex;
    min-width: 100vw;
    height: 100%;
    overflow: hidden;
    pointer-events: auto;

    justify-content: center;
  }

  &__action {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 300;
    display: flex;
    width: 50px;
    height: 50px;
    color: $white;
    pointer-events: initial;

    align-items: center;
    justify-content: center;
    &:hover {
      color: $blue;
      cursor: pointer;
    }
    .icon--close--thin {
      font-size: 2.8rem;
    }
  }


  &__background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9998;
    background-color: #000;
    // opacity: .75;
  }
  iframe {
    width: 100%;
    min-height: 50vw;
    pointer-events: all;

    @include mobile {
      // height: 149px;
    }
    @include tablet {
      // min-height: 568px;
    }
  }
}

body {
  .modal-open {
    overflow: hidden;
  }
}
