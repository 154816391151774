html {
  box-sizing: border-box;
  min-width: 100% !important;
  background-color: #f5f7fa;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: $base-font;
  color: $black;
  background-color: #f5f7fa;
}
video,
img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  transition: color 0.3s 0s ease-out, background-color 0.3s 0s ease-out, border-color 0.3s 0s ease-out;
}

button,
a {
  cursor: pointer;
}

.inner {
  max-width: 1000px;
  margin: 0 auto;
}

.hidden {
  display: none;
}

li {
  list-style: none;
}

a {
  color: $black;
}

::selection {
  color: $white;
  background-color: $blue;
}

.white {
  color: $white;
}

.blue {
  color: $blue;
}
