@font-face {
  font-family: 'site';
  font-style: normal;
  font-weight: normal;

  src: url('#{$site-font-path}/site.eot?kr9sb6');
  src: url('#{$site-font-path}/site.eot?kr9sb6#iefix') format('embedded-opentype'),
  url('#{$site-font-path}/site.ttf?kr9sb6') format('truetype'),
  url('#{$site-font-path}/site.woff?kr9sb6') format('woff'),
  url('#{$site-font-path}/site.svg?kr9sb6#site') format('svg');
}

[class^="icon--"], [class*=" icon--"] {
  font-family: 'site' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;

  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@function unicode($str) {
  @return unquote("\"") + $str + unquote("\"");
}

.icon--instagram {
  &:before {
    content: unicode($icon--instagram);
  }
}
.icon--close--thin {
  &:before {
    content: unicode($icon--close--thin);
  }
}
.icon--twitter--filled {
  &:before {
    content: unicode($icon--twitter--filled);
  }
}
.icon--twitter {
  &:before {
    content: unicode($icon--twitter);
  }
}
.icon--facebook {
  &:before {
    content: unicode($icon--facebook);
  }
}
.icon--cloud-download {
  &:before {
    content: unicode($icon--cloud-download);
  }
}
.icon--close {
  &:before {
    content: unicode($icon--close);
  }
}
.icon--email {
  &:before {
    content: unicode($icon--email);
  }
}
.icon--play {
  &:before {
    content: unicode($icon--play);
  }
}
.icon--pause {
  &:before {
    content: unicode($icon--pause);
  }
}
.icon--stop {
  &:before {
    content: unicode($icon--stop);
  }
}
.icon--linkedin {
  &:before {
    content: unicode($icon--linkedin);
  }
}
