body {
  // &.projects {
  .app-nav__item {
    a {
      color: $white;
      &:hover {
        color: lighten($blue, 50%);
      }
      &.nav-link--is-active {
        border-bottom: 1px solid $white;
      }
    }
  }
  // }
  &.about-me {
    .app-nav__item {
      a {
        color: $blue;
        &:hover {
          color: lighten($blue, 20%);
        }
        &.nav-link--is-active {
          color: lighten($blue, 20%);
          border-bottom: 1px solid lighten($blue, 20%);
        }
      }
    }
  }
  &.resume {
    .app-nav__item {
      a {
        color: $white;
        &:hover {
          color: lighten($blue, 10%);
        }
        &.nav-link--is-active {
          color: $white;
          border-bottom: 1px solid $white;
        }
      }
    }
  }
}
.app-nav {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
  display: flex;
  width: 100%;
  padding: 0 2em;
  pointer-events: all;

  justify-content: flex-end;
  -webkit-font-smoothing: antialiased;

  &::after {
    display: table;
    clear: both;
    content: "";
  }
  @media screen and (max-width: 31.875em) {
    padding: 0;
  }
  ul {
    display: flex;
    width: 100%;

    justify-content: center;
    @include tablet {
      width: inherit;

      justify-content: flex-end;
    }
  }

  li {
    display: inline-block;
    &:hover {
      cursor: pointer;
    }
    a {
      display: inline-block;
      padding: 1em;
      font-weight: bold;
      background-repeat: no-repeat;
      background-position: 1em center;
    }
    @media screen and (max-width: 32.1875em) {
      .app-nav a {
        padding: 0.75em 0.5em;
        text-align: center;
      }
    }
    @media screen and (max-width: 56.25em) {
      .app-nav a {
        display: block;
      }
    }
  }
}
