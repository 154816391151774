// Colors
$white: hsla(216, 33%, 97%, 1);
$black: hsla(215, 8%, 29%, 1);
$blue: hsla(210, 100%, 20%, 1);
$hover: hsla(210, 24%, 68%, 1);
$light-blue: hsla(210, 100%, 39%, 1);
$blue-overlay: rgba(0, 51, 102, 0.5);

// Misc
$base-font: 'Noto Sans JP', sans-serif;
$tablet: 769px;
$desktop: 1000px;
$widescreen: 1192px;
// $fullhd: 1384px;
$close-btn-size: 50px;
$close-btn-before-width: 50px;
$close-btn-before-height: 10px;
$speed: 86ms;

//
$site-font-path: "../assets/fonts" !default;
$icon--instagram: \e907;
$icon--close--thin: \e901;
$icon--twitter--filled: \e904;
$icon--twitter: \e905;
$icon--facebook: \e906;
$icon--cloud-download: \e903;
$icon--close: \e902;
$icon--email: \e900;
$icon--play: \ea15;
$icon--pause: \ea16;
$icon--stop: \ea17;
$icon--linkedin: \eac9;
